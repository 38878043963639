<template>
  <v-container fluid>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard>
          <v-row>
            <v-col cols="12" sm="5" md="4" lg="3">
              <Picker @setDate="getFluxo" />
            </v-col>
            <v-col cols="12" sm="7" md="8" lg="9">
              <Vagas
                @open="openInfoJanela"
                :data="data"
                :items="janelas_carga_descarga"
                @adicionarJanela="openFormJanela"
                @editarJanela="openFormJanela"
                :permission="permission"
              />
            </v-col>
          </v-row>
        </BaseCard>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog_info" width="1000">
      <v-card text-align="center">
        <v-card-title class="text-h5">
          Informações sobre o Agendamento
        </v-card-title>
        <v-card-text>
          <Info :agendamento="agendamento" />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="closeInfoJanela"> Fechar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Cadastro
      ref="cadastro"
      @load="load"
      @loaded="loaded"
      :data="data"
      :permission="permission"
    />
  </v-container>
</template>

<script>
import Picker from '@/components/logistica/fluxo/Picker.vue'
import Vagas from '@/components/logistica/fluxo/Vagas.vue'
import Info from '@/components/logistica/fluxo/Info.vue'
import Cadastro from '@/components/logistica/fluxo/Cadastro.vue'
import LogisticaApi from '@/services/logistica'
import { mapActions, mapState, mapMutations } from 'vuex'
import { BasicPermissions, Sequences } from '@/utils/permissions'
export default {
  data() {
    return {
      page: {
        title: 'Gerenciamento do Fluxo de Caminhões',
      },
      breadcrumbs: [
        {
          text: 'Logística',
          disabled: true,
        },
        {
          text: 'Fluxo de Caminhões',
          disabled: true,
        },
      ],
      loading: false,
      data: new Date().toISOString().split('T', 4).slice(0, 1).join('-'),
      fluxos: [],
      dialog_info: false,
      agendamento: null,
    }
  },
  components: { Picker, Vagas, Info, Cadastro },
  computed: {
    ...mapState('auth', ['empresaAtual']),
    ...mapState('logistica', ['janelas_carga_descarga']),
    permission() {
      return Sequences.FluxoCaminhoes.toString()
    },

    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },
  },
  async created() {
    await this.getFluxo()
  },
  methods: {
    ...mapActions('patio', ['getAgendamentoGerenciamentoServico']),
    ...mapActions('template', ['successMessage', 'errorMessage']),
    ...mapMutations('logistica', ['SET_JANELAS_CARGA_DESCARGA']),
    load() {
      this.loading = true
    },
    loaded() {
      this.loading = false
    },
    async getFluxoCaminhoes() {
      if (!this.canList) {
        return { results: [] }
      }
      let date = this.data
      if (!date) {
        date = new Date().toISOString().split('T', 4).slice(0, 1).join('-')
      }
      return await LogisticaApi.getFluxoCaminhoes(this.empresaAtual.public_id, {
        date: date,
      })
      // let key = 0
      // const getKey = () => {
      //   key += 1
      //   return key
      // }
      // return new Promise((resolve) => {
      //   setTimeout(() => {
      //     const data = new Array(Math.floor(Math.random() * 10))
      //       .fill(undefined)
      //       .map(() => {
      //         return {
      //           id: getKey(),
      //           data_inicio: this.data + 'T08:00',
      //           data_fim: this.data + 'T09:00',
      //           qt_caminhoes: Math.floor(Math.random() * 10) + 1,
      //           agendamentos: [],
      //         }
      //       })
      //     resolve(data)
      //   }, 300)
      // })
    },
    async getFluxo(date) {
      this.data = date
      let data = { results: [] }
      try {
        this.load()
        data = await this.getFluxoCaminhoes()
        this.SET_JANELAS_CARGA_DESCARGA(data.results)
        // this.fluxos = data.results
      } finally {
        this.loaded()
      }
      return data.results
    },
    closeInfoJanela() {
      this.dialog_info = false
    },
    openFormJanela(item = null) {
      this.$refs['cadastro'].open(item)
    },
    async openInfoJanela(item) {
      if (item.public_id) {
        this.agendamento = await this.getAgendamentoGerenciamentoServico(item)
        this.$nextTick(() => {
          this.dialog_info = true
        })
      } else {
        // Create
      }
    },
  },
}
</script>

<style></style>
