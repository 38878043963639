<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="4">
        <validation-provider v-slot="{ errors }" name="gate">
          <v-autocomplete
            v-if="!empresaAtual.gate"
            v-model="value.gate"
            label="Gate/Unidade"
            :items="gates"
            :item-text="
              (item) =>
                item.business_name +
                ' - ' +
                `${item.cnpj != null ? item.cnpj : item.cpf}`
            "
            item-value="public_id"
            return-object
            hint="Selecione um Gate/Unidade."
            :error-messages="errors"
            persistent-hint
            prepend-icon="mdi-boom-gate"
            required
            @change="setFornecedor()"
          />
          <v-text-field
            v-else
            v-model="empresaAtual.tenant"
            hint="Selecione um Gate/Unidade."
            :error-messages="errors"
            persistent-hint
            prepend-icon="mdi-boom-gate"
            :disabled="empresaAtual.gate"
          />
        </validation-provider>
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <validation-provider v-slot="{ errors }" name="fornecedor">
          <v-autocomplete
            v-model="value.fornecedor"
            label="Fornecedor"
            :items="selectFornecedor"
            :item-text="
              (item) =>
                item.business_name +
                ' - ' +
                `${item.cnpj != null ? item.cnpj : item.cpf}`
            "
            item-value="public_id"
            @change="selectedFornecedor()"
            return-object
            hint="Selecione um fornecedor."
            :error-messages="errors"
            persistent-hint
            prepend-icon="mdi-equalizer"
            :disabled="!empresaAtual.gate"
          ></v-autocomplete>
        </validation-provider>
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <validation-provider v-slot="{ errors }" name="tipo_operacao">
          <v-autocomplete
            v-model="value.tipo_operacao"
            label="Tipo de Operação"
            :items="tiposOperacao"
            item-text="descricao"
            item-value="id"
            return-object
            hint="Selecione o tipo de operação."
            :error-messages="errors"
            persistent-hint
            prepend-icon="mdi-clipboard-flow-outline"
          />
        </validation-provider>
      </v-col>
    </v-row>

    <v-divider class="my-5"></v-divider>
    <v-row>
      <v-col cols="12" sm="6" md="6">
        <validation-provider v-slot="{ errors }" name="terminal">
          <v-autocomplete
            v-model="value.terminal"
            label="Terminal"
            :item-text="
              (item) =>
                item.business_name +
                ' - ' +
                `${item.cnpj != null ? item.cnpj : item.cpf}`
            "
            item-value="public_id"
            :items="selectTerminal"
            return-object
            hint="Selecione um terminal."
            :error-messages="errors"
            persistent-hint
            prepend-icon="mdi-alpha-t-box-outline"
          ></v-autocomplete>
        </validation-provider>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <validation-provider v-slot="{ errors }" name="servico">
          <v-autocomplete
            v-model="value.servico"
            label="Serviço"
            item-text="descricao"
            item-value="id"
            :items="selectServico"
            return-object
            hint="Selecione um serviço."
            :error-messages="errors"
            persistent-hint
            prepend-icon="mdi-toolbox-outline"
          ></v-autocomplete>
        </validation-provider>
      </v-col>
    </v-row>
    <v-divider class="my-5"></v-divider>
    <v-row>
      <v-col class="pt-0" cols="12" sm="4">
        <validation-provider v-slot="{ errors }" name="tipo_controle">
          <v-autocomplete
            class="mt-3"
            v-model="value.tipo_controle"
            label="Tipo de controle"
            :error-messages="errors"
            :items="tiposControle"
            item-text="descricao"
            item-value="id"
            hint="Como os tipos de veículos será utilizado."
            persistent-hint
            prepend-icon="mdi-clipboard-alert-outline"
            return-object
          ></v-autocomplete>
        </validation-provider>
      </v-col>
      <v-col class="pt-0" cols="12" sm="4">
        <validation-provider v-slot="{ errors }" name="modalidades">
          <v-autocomplete
            v-model="value.modalidades"
            label="Tipos de Veículo"
            :items="modalidades"
            :error-messages="errors"
            item-text="descricao"
            item-value="id"
            multiple
            chips
            hint="Selecione o tipo de veículo."
            persistent-hint
            prepend-icon="mdi-truck-outline"
            return-object
          ></v-autocomplete>
        </validation-provider>
      </v-col>
      <v-col class="pt-0" cols="12" sm="4">
        <validation-provider v-slot="{ errors }" name="carrocerias">
          <v-autocomplete
            v-model="value.carrocerias"
            label="Tipos de carroceria"
            :items="carrocerias"
            :error-messages="errors"
            item-text="descricao"
            item-value="id"
            multiple
            chips
            hint="Selecione o tipo de carroceria."
            persistent-hint
            prepend-icon="mdi-truck-outline"
            return-object
          ></v-autocomplete>
        </validation-provider>
      </v-col>
    </v-row>
    <v-divider class="my-5"></v-divider>
    <v-row>
      <v-col cols="12" md="6" lg="6">
        <validation-provider v-slot="{ errors }" name="produto">
          <v-autocomplete
            v-model="value.produto"
            label="Produto"
            item-text="descricao"
            item-value="id"
            :items="selectProduto"
            @change="selectedProduto()"
            return-object
            hint="Selecione um produto."
            :error-messages="errors"
            persistent-hint
            prepend-icon="mdi-alpha-p-box-outline"
          ></v-autocomplete>
        </validation-provider>
      </v-col>
      <v-col cols="12" md="6" lg="6">
        <validation-provider v-slot="{ errors }" name="sub_produto">
          <v-autocomplete
            v-model="value.sub_produto"
            item-text="descricao"
            label="Sub Produto"
            item-value="id"
            :items="selectSubProduto"
            return-object
            hint="Selecione um sub produto."
            :error-messages="errors"
            persistent-hint
            prepend-icon="mdi-alpha-p-circle-outline"
          ></v-autocomplete>
        </validation-provider>
      </v-col>
    </v-row>
    <v-divider class="my-5"></v-divider>
    <v-row>
      <v-col class="pt-0" cols="12" sm="12">
        <validation-provider v-slot="{ errors }" name="observacao">
          <v-text-field
            v-model="value.observacao"
            :error-messages="errors"
            label="Observação"
            prepend-icon="mdi-note-text-outline"
            hint="Digite uma observação."
            persistent-hint
          />
        </validation-provider>
      </v-col>
    </v-row>
    <v-divider class="my-5"></v-divider>
    <v-row>
      <v-col cols="12" md="12" lg="4">
        <validation-provider
          v-slot="{ errors }"
          name="data_inicio"
          rules="required"
        >
          <v-text-field
            v-model="value.hora_inicio"
            :error-messages="errors"
            label="Hora Inicial"
            v-mask="['##:##']"
            prepend-icon="mdi-clock-time-four-outline"
            hint="Escolha a hora inicial."
            persistent-hint
            :disabled="!!value.hora_fim"
          ></v-text-field>
        </validation-provider>
      </v-col>
      <v-col cols="12" md="12" lg="4">
        <validation-provider
          v-slot="{ errors }"
          name="data_fim"
          rules="required"
        >
          <v-text-field
            v-model="value.hora_fim"
            :error-messages="errors"
            label="Hora Final"
            v-mask="['##:##']"
            prepend-icon="mdi-clock-time-four-outline"
            hint="Escolha a hora final."
            persistent-hint
            :rules="rules"
            :disabled="!value.hora_inicio"
          ></v-text-field>
        </validation-provider>
      </v-col>
      <v-col cols="12" md="12" lg="4">
        <validation-provider
          v-slot="{ errors }"
          name="qt_caminhoes"
          rules="required"
        >
          <v-text-field
            type="number"
            v-model="value.qt_caminhoes"
            label="Caminhões"
            hint="Indique a quantidade de caminhões permitida."
            :error-messages="errors"
            persistent-hint
            prepend-icon="mdi-truck-check-outline"
          ></v-text-field>
        </validation-provider>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ContratoAPI from '@/services/tenants/contratos'
import Perfil from '@/utils/perfil'
import { mapActions, mapState, mapMutations } from 'vuex'
export default {
  props: {
    value: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      menu1: false,
      menu2: false,
      rules: [(value) => this.regraHora(value)],
      selectFornecedor: [],
      selectProduto: [],
      selectSubProduto: [],
      selectTerminal: [],
      selectServico: [],
      gates: [],
      servicos: [],
    }
  },
  computed: {
    ...mapState('patio', [
      'terminais',
      'tiposOperacao',
      'modalidades',
      'carrocerias',
      'tiposControle',
    ]),
    ...mapState('subProdutos', ['subProdutos']),
    ...mapState('produtos', ['produtos']),
    ...mapState('fornecedores', ['fornecedores']),
    ...mapState('auth', ['empresaAtual']),
  },
  async created() {
    await this.atualizarListaTiposControle()
    await this.atualizarListaModalidades()
    await this.atualizarListaCarrocerias()
    await this.getTipoOperacao()
    if (!this.empresaAtual.gate) {
      const { possible_gates } = await ContratoAPI.getPossiveisGates(
        this.empresaAtual.public_id
      )
      this.gates = [...possible_gates]
    } else {
      this.gates = [this.empresaAtual]
    }
    if (this.empresaAtual.gate) {
      this.value.gate = this.empresaAtual
      this.setFornecedor()
    }
  },
  methods: {
    ...mapActions('patio', [
      'deleteProgramacaoUnidade',
      'getTiposControle',
      'getCarrocerias',
      'getModalidades',
      'getTipoOperacao',
    ]),
    ...mapActions('fornecedores', ['getFornecedoresByParams']),
    ...mapMutations('patio', ['setTerminal']),
    ...mapMutations('produtos', ['setProduto']),
    ...mapMutations('subProdutos', ['setSubProduto']),
    ...mapActions('patio', ['getServicos']),

    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),
    async setServico() {
      if (this.value.gate && this.value.gate.public_id) {
        if (this.value.fornecedor && this.value.fornecedor.public_id) {
          this.servicos = this.value.fornecedor.servicos_autorizados.map(
            (fsa) => fsa.servico
          )
        } else {
          this.servicos = await this.getServicos({
            proprietario: this.value.gate.public_id,
          })
        }
      } else {
        this.servicos = []
      }
    },
    async setFornecedor() {
      if (!this.empresaAtual.gate) {
        if (this.value.gate) {
          // const fornecedor = this.value.gate.fornecedores.find(
          //   (f) => f.fornecedor_id === this.empresaAtual.public_id
          // )
          const fornecedores = await this.getFornecedoresByParams({
            empresa: this.value.gate,
            params: {
              fornecedor: this.empresaAtual.public_id,
            },
          })
          const fornecedor = fornecedores.find(
            (f) => f.fornecedor_id === this.empresaAtual.public_id
          )
          this.selectFornecedor = [fornecedor]
          this.value.fornecedor = fornecedor
        }
      } else {
        await this.atualizarListaServico()
        await this.atualizarListaFornecedores()
      }
      await this.selectedFornecedor()
    },
    async atualizarListaServico() {
      this.setServico()
      this.selectServico = [{ descricao: 'Nenhum', id: null }, ...this.servicos]
    },
    async atualizarListaModalidades() {
      try {
        await this.getModalidades()
      } catch (error) {
        if (error.response && error.response.status == 404) {
          this.errorMessage('Empresa não é um gate.')
        } else {
          this.errorMessage('Ocorreu algum erro inesperado.')
        }
      }
    },
    async atualizarListaCarrocerias() {
      try {
        await this.getCarrocerias()
      } catch (error) {
        if (error.response && error.response.status == 404) {
          this.errorMessage('Empresa não é um gate.')
        } else {
          this.errorMessage('Ocorreu algum erro inesperado.')
        }
      }
    },
    async atualizarListaTiposControle() {
      try {
        await this.getTiposControle()
      } catch (error) {
        if (error.response) {
          if (
            error.response &&
            (error.response.status == 403 || error.response.status == 404)
          ) {
            this.errorMessage('Empresa não é gate para ter fornecedores.')
            return
          }
          if (error.response.data) {
            this.errorMessage(error.response.data)
            return
          }
          if (error.message) {
            this.errorMessage(error.message)
            return
          }
          this.errorMessage(error)
        }
      }
    },
    async atualizarListaFornecedores() {
      try {
        const fornecedores = await this.getFornecedoresByParams({
          empresa: this.value.gate,
        })
        this.selectFornecedor = [
          {
            business_name: 'Nenhum',
            cnpj: '000.000.000/0000-00',
            cpf: '000.000.000-00',
            public_id: null,
          },
          ...fornecedores,
        ]
      } catch (error) {
        if (error.response) {
          if (
            error.response &&
            (error.response.status == 403 || error.response.status == 404)
          ) {
            this.errorMessage('Empresa não é gate para ter fornecedores.')
            return
          }
          if (error.response.data) {
            this.errorMessage(error.response.data)
            return
          }
          if (error.message) {
            this.errorMessage(error.message)
            return
          }
          this.errorMessage(error)
        }
      }
    },
    async selectedFornecedor() {
      await this.setServico()
      this.selectServico = [{ descricao: 'Nenhum', id: null }, ...this.servicos]
      if (this.value.fornecedor != null) {
        if (this.value.fornecedor.public_id != null) {
          this.setProduto(this.value.fornecedor.produtos)
          this.selectProduto = [
            { descricao: 'Nenhum', id: null },
            ...this.produtos,
          ]
          this.setTerminal(
            await ContratoAPI.getContratos(
              this.value.fornecedor.fornecedor_id,
              {
                perfil: Perfil.Terminal,
              }
            )
          )
          this.selectTerminal = [
            {
              business_name: 'Nenhum',
              cnpj: '000.000.000/0000-00',
              cpf: '000.000.000-00',
              public_id: null,
            },
            ...this.terminais,
          ]
        } else {
          this.selectProduto = [{ descricao: 'Nenhum', id: null }]
          this.selectTerminal = [
            { business_name: 'Nenhum', cnpj: '', cpf: '', public_id: null },
          ]
          this.value.servico = null
          this.value.produto = null
          this.value.sub_produto = null
          this.value.terminal = null
        }
      } else {
        this.selectProduto = [{ descricao: 'Nenhum', id: null }]
        this.selectTerminal = [
          { business_name: 'Nenhum', cnpj: '', cpf: '', public_id: null },
        ]
        this.value.servico = null
        this.value.produto = null
        this.value.sub_produto = null
        this.value.terminal = null
      }
      this.selectedProduto()
    },
    selectedProduto() {
      if (this.value.produto != null) {
        if (this.value.produto.id != null) {
          this.setSubProduto(this.value.produto.subprodutos)
          this.selectSubProduto = [
            { descricao: 'Nenhum', id: null },
            ...this.subProdutos,
          ]
        } else {
          this.selectSubProduto = [{ descricao: 'Nenhum', id: null }]
          this.value.sub_produto = null
        }
      } else {
        this.selectSubProduto = [{ descricao: 'Nenhum', id: null }]
        this.value.sub_produto = null
      }
    },
    regraHora(value) {
      const inicio = this.value.hora_inicio
      const date = new Date()
      const data_inicio = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      )
      const hora_inicio = inicio.split(':')
      data_inicio.setHours(hora_inicio[0])
      if (hora_inicio.length == 1) {
        data_inicio.setMinutes('00')
      } else {
        data_inicio.setMinutes(hora_inicio[1])
      }
      const data_fim = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      )
      const hora_fim = value.split(':')
      data_fim.setHours(hora_fim[0])
      if (hora_fim.length == 1) {
        data_fim.setMinutes('00')
      } else {
        data_fim.setMinutes(hora_fim[1])
      }
      // const inicio = new Date().from
      return data_inicio < data_fim || 'Hora fim dever ser maior que a inicial'
    },
  },
}
</script>
