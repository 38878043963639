<template>
  <div>
    <v-date-picker
      v-model="picker"
      show-adjacent-months
      full-width
      :show-current="true"
      @click:date="selectedDate"
    />
    <div class="d-flex flex-column">
      <!-- <v-date-picker
        v-model="picker"
        :min="mes"
        :max="mes_futuro"
      ></v-date-picker>
      <v-date-picker v-model="picker" :min="mes_futuro"></v-date-picker> -->
    </div>
  </div>
</template>

<script>
//   import { mapActions, mapState } from 'vuex'
export default {
  props: {},
  data() {
    return { picker: null }
  },
  computed: {},
  async created() {
    this.picker = new Date().toISOString().split('T', 4).slice(0, 1).join('-')
    this.today = new Date()
    this.mes_atual = this.today
      .toISOString()
      .split('T', 4)
      .slice(0, 1)
      .join('-')
    const mes_futuro = new Date()
    mes_futuro.setMonth(this.today.getMonth() + 1)
    this.mes_futuro = mes_futuro
      .toISOString()
      .split('-', 3)
      .slice(0, 2)
      .join('-')
  },
  methods: {
    selectedDate(date) {
      this.$emit('setDate', date)
    },
  },
}
</script>
