<template>
  <div>
    <v-dialog v-model="dialog" width="1000">
      <v-card text-align="center">
        <v-card-title class="text-h5">
          <v-row>
            <v-col cols="12" md="4">
              <div class="d-flex flex-column align-start">
                <span class="headline">{{ formTitle }}</span>
                <span class="text-caption">{{
                  editedItem.public_id || 'Não criada'
                }}</span>
                <span class="text-caption" v-if="!salvavel">
                  Jenela antiga. Não pode ser editada.
                </span>
              </div>
            </v-col>
            <v-col cols="12" md="4" align="center">
              <span>{{ data | date2br }}</span>
            </v-col>
            <v-col cols="12" md="4" align="end">
              <v-btn color="primary" @click="close"> Fechar </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <CadastroForm v-model="editedItem" ref="CadastroForm" />
        </v-card-text>
        <v-card-actions
          ><v-btn
            color="red darken-1"
            text
            v-if="editavel && canDelete"
            @click="deletar"
            >Deletar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="close">Cancelar</v-btn>
          <v-btn color="blue darken-1" :disabled="!salvavel" text @click="save"
            >Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import CadastroForm from '@/components/logistica/fluxo/CadastroForm.vue'
import { date2br } from '@/mixins/convertion'
import { BasicPermissions } from '@/utils/permissions'
export default {
  props: {
    data: {
      type: String,
      require: true,
    },
    permission: {
      type: String,
      required: false,
      default: null,
    },
  },
  components: { CadastroForm },
  mixins: [date2br],
  data() {
    return {
      dialog: false,
      editedItem: {
        public_id: '',
        gate: null,
        fornecedor: null,
        tipo_operacao: null,
        terminal: null,
        servico_autorizado: null,
        tipo_controle: null,
        modalidades: [],
        carrocerias: [],
        produto: null,
        sub_produto: null,
        observacao: '',
        hora_inicio: '',
        hora_fim: '',
        qt_caminhoes: 0,
      },
    }
  },
  computed: {
    ...mapState('auth', ['empresaAtual']),
    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },
    formTitle() {
      return this.editedItem.public_id ? `Editar Janela` : `Adicionar Janela`
    },
    salvavel() {
      if (this.editedItem.public_id == null || this.editedItem.public_id == '')
        return this.canCreate
      return this.editavel
    },
    editavel() {
      if (
        this.editedItem.public_id != null &&
        this.editedItem.public_id != ''
      ) {
        const add_3_hours = 1000 * 60 * 60 * 3
        const data = new Date(this.editedItem.data_fim)
        data.setTime(data.getTime() + add_3_hours)
        if (data > new Date()) {
          return this.canUpdate
        }
      }
      return false
    },
  },
  async created() {},
  methods: {
    ...mapActions('logistica', [
      'getJanelaCargaDescarga',
      'addJanelaCargaDescarga',
      'editJanelaCargaDescarga',
      'deleteJanelaCargaDescarga',
    ]),
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),
    ...mapMutations('logistica', ['EDIT_JANELA_CARGA_DESCARGA']),
    async deletar() {
      try {
        this.$emit('load')
        await this.deleteJanelaCargaDescarga(this.editedItem)
        this.successMessage('Janela deletada!')
        this.clear(this.editedItem)
        this.close()
      } catch (error) {
        if (error.response) {
          if (error.response.data) {
            const data = error.response.data
            if (data.error_quantidade) {
              this.errorMessage(data.error_quantidade)
              if (data.novo_qt_caminhoes) {
                this.editedItem.qt_caminhoes = data.novo_qt_caminhoes
                this.EDIT_JANELA_CARGA_DESCARGA(this.editedItem)
                this.clear(this.editedItem)
                this.close()
              }
              return
            }
          }
          this.errorMessage(error.response.data)
          return
        }
        if (error.message) {
          this.errorMessage(error.message)
          return
        }
        this.errorMessage(error)
      } finally {
        this.$emit('loaded')
      }
    },
    ajustaDatas() {
      let data_inicio, data_fim, date
      if (!this.data) {
        date = new Date()
      } else {
        const _date = this.data.split('-')
        date = new Date(_date[0] * 1, _date[1] * 1 - 1, _date[2] * 1)
      }
      data_inicio = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      )
      const add_3_hours = 1000 * 60 * 60 * 3
      const hora_inicio = this.editedItem.hora_inicio.split(':')
      data_inicio.setHours(hora_inicio[0])
      data_inicio.setTime(data_inicio.getTime() - add_3_hours)
      if (hora_inicio.length == 1) {
        data_inicio.setMinutes('00')
      } else {
        data_inicio.setMinutes(hora_inicio[1])
      }
      data_fim = new Date(date.getFullYear(), date.getMonth(), date.getDate())
      const hora_fim = this.editedItem.hora_fim.split(':')
      data_fim.setHours(hora_fim[0])
      data_fim.setTime(data_fim.getTime() - add_3_hours)
      if (hora_fim.length == 1) {
        data_fim.setMinutes('00')
      } else {
        data_fim.setMinutes(hora_fim[1])
      }

      return { data_inicio, data_fim }
    },
    ajustaItem() {
      const datas = this.ajustaDatas()
      let item = {
        public_id: this.editedItem.public_id,
        proprietario: this.empresaAtual.tenant,
        gate: this.editedItem.gate?.public_id,
        fornecedor: this.editedItem.fornecedor?.public_id,
        tipo_operacao: this.editedItem.tipo_operacao?.id,
        terminal: this.editedItem.terminal?.public_id,
        servico: this.editedItem.servico?.id,
        tipo_controle: this.editedItem.tipo_controle?.id,
        modalidades: this.editedItem.modalidades
          ? this.editedItem.modalidades.map((i) => i.id)
          : [],
        carrocerias: this.editedItem.carrocerias
          ? this.editedItem.carrocerias.map((i) => i.id)
          : [],
        produto: this.editedItem.produto?.id,
        sub_produto: this.editedItem.sub_produto?.id,
        observacao: this.editedItem.observacao,
        data_inicio: datas.data_inicio.toISOString(),
        data_fim: datas.data_fim.toISOString(),
        qt_caminhoes: this.editedItem.qt_caminhoes,
      }
      return item
    },
    clear(item, horas = true) {
      Object.keys(item).forEach((key) => {
        if (Array.isArray(item[key])) {
          let counter_loop = 0
          while (item[key].length > 0 && counter_loop < 100) {
            item[key].pop()
            counter_loop++
          }
        } else {
          item[key] = null
        }
      })
      if (horas) {
        item.hora_inicio = ''
        item.hora_fim = ''
      }
    },
    async save() {
      try {
        this.$emit('load')
        const item = this.ajustaItem(this.editedItem)
        if (item.public_id) {
          await this.editJanelaCargaDescarga(item)
          this.successMessage('Janela editada!')
        } else {
          await this.addJanelaCargaDescarga(item)
          this.successMessage('Janela cadastrada!')
        }
        this.clear(this.editedItem)
        this.close()
      } catch (error) {
        this.$emit('loaded')
        if (error.response) {
          if (error.response.status === 404) {
            let helper = ''
            if (this.editedItem.proprietario) {
              const proprietario =
                this.editedItem.proprietario.business_name +
                ' - ' +
                `${
                  this.editedItem.proprietario.cnpj != null
                    ? this.editedItem.proprietario.cnpj
                    : this.editedItem.proprietario.cpf
                }`
              if (this.editedItem.proprietario.business_name != undefined) {
                helper = ' Fale com ' + proprietario
              }
            }
            this.errorMessage(
              'Você não tem permissão para editar esse item.' + helper
            )
            return
          }
          this.errorMessage(error.response.data)
          return
        }
        if (error.message) {
          this.errorMessage(error.message)
          return
        }
        this.errorMessage(error)
      } finally {
        this.$emit('loaded')
      }
    },
    close() {
      this.dialog = false
    },
    async open(item) {
      if (item) {
        await this.editItem(item)
      } else {
        this.clear(this.editedItem)
      }
      this.dialog = true
      this.$nextTick(() => {
        if (this.empresaAtual.gate) {
          this.editedItem.gate = this.empresaAtual
        }
        this.$refs['CadastroForm'].setFornecedor()
      })
    },
    async editItem(item) {
      if (item.public_id) {
        try {
          this.$emit('load')
          item = await this.getJanelaCargaDescarga(item)
        } finally {
          this.$emit('loaded')
        }
      }
      this.clear(this.editedItem)
      Object.assign(this.editedItem, item)
      this.editedItem.hora_inicio = item.data_inicio
        .split('T')[1]
        .split(':')
        .slice(0, 2)
        .join(':')
      this.editedItem.hora_fim = item.data_fim
        .split('T')[1]
        .split(':')
        .slice(0, 2)
        .join(':')
      if (this.empresaAtual.gate && !item.public_id) {
        this.editedItem.gate = this.empresaAtual
      }
    },
  },
}
</script>
