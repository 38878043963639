<template>
  <div>
    <v-row class="">
      <v-col class="d-flex" cols="12">
        <v-divider class="ma-5" />
        <span class="text-h6"> Informações das Empresas </span>
        <v-divider class="ma-5" />
      </v-col>
      <v-col class="" cols="12" sm="6">
        Gate: <b>{{ getEmpresaText('gate') }}</b>
      </v-col>
      <v-col class="" cols="12" sm="6">
        Fornecedor: <b>{{ getEmpresaText('fornecedor') }}</b>
      </v-col>
      <v-col class="" cols="12" sm="6">
        Cliente: <b>{{ getEmpresaText('cliente') }}</b>
      </v-col>
      <v-col class="" cols="12" sm="6">
        Transportadora: <b>{{ getEmpresaText('transportadora') }}</b>
      </v-col>
      <v-col class="" cols="12" sm="6">
        Remetente: <b>{{ getEmpresaText('remetente') }}</b>
      </v-col>
      <v-col class="" cols="12" sm="6">
        Destinatário: <b>{{ getEmpresaText('destinatario') }}</b>
      </v-col>
    </v-row>
    <v-row class="">
      <v-col class="d-flex" cols="12">
        <v-divider class="ma-5" />
        <span class="text-h6"> Informações da Carga </span>
        <v-divider class="ma-5" />
      </v-col>
      <v-col class="" cols="12" sm="3">
        Tipo de Frete: <b>{{ getDescricaoText('tipo_frete') }}</b>
      </v-col>
      <v-col class="" cols="12" sm="3">
        Tipo de Operação: <b>{{ getDescricaoText('tipo_operacao') }}</b>
      </v-col>
      <v-col class="" cols="12" sm="3">
        Sub Produto
        <b>{{
          getFirstText('pedidos_agendados', [
            'pedido',
            'subProduto',
            'descricao',
          ])
        }}</b>
      </v-col>
      <v-col class="" cols="12" sm="3">
        Quantidade
        <b>{{
          getFirstText('pedidos_agendados', ['quantidade_agendada']) * 1 * 1000
        }}</b>
      </v-col>
    </v-row>
    <v-row class="">
      <v-col class="d-flex" cols="12">
        <v-divider class="ma-5" />
        <span class="text-h6"> Informações do Veículo </span>
        <v-divider class="ma-5" />
      </v-col>
      <v-col class="" cols="12" sm="4">
        Placa Cavalo: <b>{{ getText(['veiculo', 'placa_cavalo']) }}</b>
      </v-col>
      <v-col class="" cols="12" sm="4">
        Placa Carreta 1: <b>{{ getText(['veiculo', 'placa_carreta_1']) }}</b>
      </v-col>
      <v-col class="" cols="12" sm="4">
        Placa Carreta 2: <b>{{ getText(['veiculo', 'placa_carreta_2']) }}</b>
      </v-col>
    </v-row>
    <v-row class="">
      <v-col class="d-flex" cols="12">
        <v-divider class="ma-5" />
        <span class="text-h6"> Informações das Motorista </span>
        <v-divider class="ma-5" />
      </v-col>
      <v-col class="" cols="12" sm="6" md="3">
        Motorista: <b>{{ getText(['motorista', 'user', 'name']) }}</b>
      </v-col>
      <v-col class="" cols="12" sm="6" md="3">
        CPF: <b>{{ getText(['motorista', 'user', 'cpf']) }}</b>
      </v-col>
      <v-col class="" cols="12" sm="6" md="3">
        CNH: <b>{{ getText(['motorista', 'user', 'cnh_registro']) }}</b>
      </v-col>
      <v-col class="" cols="12" sm="6" md="3">
        Celular: <b>{{ getText(['motorista', 'user', 'celular']) }}</b>
      </v-col>
    </v-row>
    <v-divider class="my-5" />
    <v-row class="">
      <v-col class="" cols="12">
        Obs.: <b>{{ getText(['observacao']) }}</b>
      </v-col>
    </v-row>
    <v-divider class="my-5" />
  </div>
</template>

<script>
//   import { mapActions, mapState } from 'vuex'
export default {
  props: {
    agendamento: {
      type: Object,
      require: true,
    },
  },
  data() {
    return { picker: null }
  },
  computed: {},
  async created() {},
  methods: {
    getEmpresaText(field) {
      if (this.agendamento && this.agendamento[field]) {
        const empresa = this.agendamento[field]
        return (
          empresa.business_name +
          ' - ' +
          `${empresa.cnpj != null ? empresa.cnpj : empresa.cpf}`
        )
      }
      return ''
    },
    getDescricaoText(field) {
      if (this.agendamento && this.agendamento[field]) {
        const item = this.agendamento[field]
        return item.descricao
      }
      return ''
    },
    getText(fields) {
      let obj = this.agendamento
      fields.forEach((element) => {
        if (obj[element]) {
          obj = obj[element]
        } else {
          obj = ''
        }
      })
      return obj
    },
    getFirstText(from, fields) {
      if (this.agendamento[from]) {
        if (this.agendamento[from].length > 0) {
          let obj = this.agendamento[from][0]
          fields.forEach((element) => {
            if (obj[element]) {
              obj = obj[element]
            } else {
              obj = ''
            }
          })
          return obj
        }
      }
      return ''
    },
  },
}
</script>
