<template>
  <div>
    <v-card>
      <v-card-title class="text-h5">
        <v-row>
          <v-col cols="12" md="4">
            <span>Vagas disponíveis</span>
          </v-col>
          <v-col cols="12" md="4" align="center">
            <span>{{ data | date2br }}</span>
          </v-col>
          <v-col cols="12" md="4" align="end">
            <v-btn
              color="primary"
              dark
              v-if="canCreate && checkHora"
              @click="adicionarJanela"
              >Nova Janela</v-btn
            >
          </v-col>
        </v-row>
      </v-card-title>
      <div>
        <v-data-table
          :headers="headers"
          :items="customItems"
          :items-per-page="-1"
          hide-default-footer
          class="elevation-1"
          item-key="id"
          itemsPer
          dense
        >
          <template v-slot:item.vagas="{ item }">
            <div class="d-flex flex-wrap borded">
              <div
                class="mx-2"
                v-for="vaga in item.vagas"
                :key="vaga.key"
                @click="open(vaga)"
              >
                <v-icon
                  x-large
                  :color="!vaga.public_id ? 'green' : 'red'"
                  :style="vaga.public_id ? 'cursor: pointer' : ''"
                >
                  mdi-truck
                </v-icon>
              </div>
            </div>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              class="mr-2"
              v-if="canUpdate"
              @click="editarJanela(item)"
              >mdi-pencil</v-icon
            >
          </template>
          <template #no-data>
            <div v-if="!canList">
              <span class="red--text"
                >Você não tem permissão para visualizar as informações desta
                tela</span
              >
            </div>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </div>
</template>

<script>
//   import { mapActions, mapState } from 'vuex'
import { date2br } from '@/mixins/convertion'
import { BasicPermissions } from '@/utils/permissions'
export default {
  props: {
    data: {
      type: String,
      require: true,
    },
    items: {
      type: Array,
      require: true,
    },
    permission: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      headers: [
        { text: 'De', value: 'hora_inicio' },
        { text: 'Até', value: 'hora_fim' },
        { text: 'Quantidade', value: 'qt_caminhoes' },
        { text: 'vagas', value: 'vagas' },
        { text: 'actions', value: 'actions' },
      ],
    }
  },
  mixins: [date2br],
  computed: {
    checkHora() {
      if (!this.data) {
        return true
      }
      const date = new Date()
      const data_hoje = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      )
      const _date = this.data.split('-')
      const data_selecionada = new Date(
        _date[0] * 1,
        _date[1] * 1 - 1,
        _date[2] * 1
      )
      return data_selecionada >= data_hoje
    },
    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },
    customItems() {
      if (!this.canList) {
        return []
      }
      if (!this.items) {
        return []
      }
      return this.items.map((item) => {
        let key = 0
        const getKey = () => {
          key += 1
          return key
        }
        const restante = new Array(
          item.qt_caminhoes - (item.agendamentos || []).length
        ).fill({
          public_id: '',
        })
        const vagas = []
          .concat(item.agendamentos || [], restante)
          .map((item) => {
            return {
              ...item,
              key: getKey(),
            }
          })

        return {
          ...item,
          width: '1%',
          vagas: vagas,
          hora_inicio: item.data_inicio
            .split('T')[1]
            .split(':')
            .slice(0, 2)
            .join(':'),
          hora_fim: item.data_fim
            .split('T')[1]
            .split(':')
            .slice(0, 2)
            .join(':'),
        }
      })
    },
  },
  async created() {},
  methods: {
    adicionarJanela() {
      this.$emit('adicionarJanela')
    },
    editarJanela(item) {
      this.$emit('editarJanela', item)
    },
    open(item) {
      this.$emit('open', item)
    },
  },
}
</script>
