var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"gate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(!_vm.empresaAtual.gate)?_c('v-autocomplete',{attrs:{"label":"Gate/Unidade","items":_vm.gates,"item-text":function (item) { return item.business_name +
              ' - ' +
              "" + (item.cnpj != null ? item.cnpj : item.cpf); },"item-value":"public_id","return-object":"","hint":"Selecione um Gate/Unidade.","error-messages":errors,"persistent-hint":"","prepend-icon":"mdi-boom-gate","required":""},on:{"change":function($event){return _vm.setFornecedor()}},model:{value:(_vm.value.gate),callback:function ($$v) {_vm.$set(_vm.value, "gate", $$v)},expression:"value.gate"}}):_c('v-text-field',{attrs:{"hint":"Selecione um Gate/Unidade.","error-messages":errors,"persistent-hint":"","prepend-icon":"mdi-boom-gate","disabled":_vm.empresaAtual.gate},model:{value:(_vm.empresaAtual.tenant),callback:function ($$v) {_vm.$set(_vm.empresaAtual, "tenant", $$v)},expression:"empresaAtual.tenant"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"fornecedor"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"Fornecedor","items":_vm.selectFornecedor,"item-text":function (item) { return item.business_name +
              ' - ' +
              "" + (item.cnpj != null ? item.cnpj : item.cpf); },"item-value":"public_id","return-object":"","hint":"Selecione um fornecedor.","error-messages":errors,"persistent-hint":"","prepend-icon":"mdi-equalizer","disabled":!_vm.empresaAtual.gate},on:{"change":function($event){return _vm.selectedFornecedor()}},model:{value:(_vm.value.fornecedor),callback:function ($$v) {_vm.$set(_vm.value, "fornecedor", $$v)},expression:"value.fornecedor"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"tipo_operacao"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"Tipo de Operação","items":_vm.tiposOperacao,"item-text":"descricao","item-value":"id","return-object":"","hint":"Selecione o tipo de operação.","error-messages":errors,"persistent-hint":"","prepend-icon":"mdi-clipboard-flow-outline"},model:{value:(_vm.value.tipo_operacao),callback:function ($$v) {_vm.$set(_vm.value, "tipo_operacao", $$v)},expression:"value.tipo_operacao"}})]}}])})],1)],1),_c('v-divider',{staticClass:"my-5"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('validation-provider',{attrs:{"name":"terminal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"Terminal","item-text":function (item) { return item.business_name +
              ' - ' +
              "" + (item.cnpj != null ? item.cnpj : item.cpf); },"item-value":"public_id","items":_vm.selectTerminal,"return-object":"","hint":"Selecione um terminal.","error-messages":errors,"persistent-hint":"","prepend-icon":"mdi-alpha-t-box-outline"},model:{value:(_vm.value.terminal),callback:function ($$v) {_vm.$set(_vm.value, "terminal", $$v)},expression:"value.terminal"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('validation-provider',{attrs:{"name":"servico"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"Serviço","item-text":"descricao","item-value":"id","items":_vm.selectServico,"return-object":"","hint":"Selecione um serviço.","error-messages":errors,"persistent-hint":"","prepend-icon":"mdi-toolbox-outline"},model:{value:(_vm.value.servico),callback:function ($$v) {_vm.$set(_vm.value, "servico", $$v)},expression:"value.servico"}})]}}])})],1)],1),_c('v-divider',{staticClass:"my-5"}),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","sm":"4"}},[_c('validation-provider',{attrs:{"name":"tipo_controle"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"mt-3",attrs:{"label":"Tipo de controle","error-messages":errors,"items":_vm.tiposControle,"item-text":"descricao","item-value":"id","hint":"Como os tipos de veículos será utilizado.","persistent-hint":"","prepend-icon":"mdi-clipboard-alert-outline","return-object":""},model:{value:(_vm.value.tipo_controle),callback:function ($$v) {_vm.$set(_vm.value, "tipo_controle", $$v)},expression:"value.tipo_controle"}})]}}])})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","sm":"4"}},[_c('validation-provider',{attrs:{"name":"modalidades"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"Tipos de Veículo","items":_vm.modalidades,"error-messages":errors,"item-text":"descricao","item-value":"id","multiple":"","chips":"","hint":"Selecione o tipo de veículo.","persistent-hint":"","prepend-icon":"mdi-truck-outline","return-object":""},model:{value:(_vm.value.modalidades),callback:function ($$v) {_vm.$set(_vm.value, "modalidades", $$v)},expression:"value.modalidades"}})]}}])})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","sm":"4"}},[_c('validation-provider',{attrs:{"name":"carrocerias"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"Tipos de carroceria","items":_vm.carrocerias,"error-messages":errors,"item-text":"descricao","item-value":"id","multiple":"","chips":"","hint":"Selecione o tipo de carroceria.","persistent-hint":"","prepend-icon":"mdi-truck-outline","return-object":""},model:{value:(_vm.value.carrocerias),callback:function ($$v) {_vm.$set(_vm.value, "carrocerias", $$v)},expression:"value.carrocerias"}})]}}])})],1)],1),_c('v-divider',{staticClass:"my-5"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":"produto"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"Produto","item-text":"descricao","item-value":"id","items":_vm.selectProduto,"return-object":"","hint":"Selecione um produto.","error-messages":errors,"persistent-hint":"","prepend-icon":"mdi-alpha-p-box-outline"},on:{"change":function($event){return _vm.selectedProduto()}},model:{value:(_vm.value.produto),callback:function ($$v) {_vm.$set(_vm.value, "produto", $$v)},expression:"value.produto"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":"sub_produto"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"item-text":"descricao","label":"Sub Produto","item-value":"id","items":_vm.selectSubProduto,"return-object":"","hint":"Selecione um sub produto.","error-messages":errors,"persistent-hint":"","prepend-icon":"mdi-alpha-p-circle-outline"},model:{value:(_vm.value.sub_produto),callback:function ($$v) {_vm.$set(_vm.value, "sub_produto", $$v)},expression:"value.sub_produto"}})]}}])})],1)],1),_c('v-divider',{staticClass:"my-5"}),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","sm":"12"}},[_c('validation-provider',{attrs:{"name":"observacao"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Observação","prepend-icon":"mdi-note-text-outline","hint":"Digite uma observação.","persistent-hint":""},model:{value:(_vm.value.observacao),callback:function ($$v) {_vm.$set(_vm.value, "observacao", $$v)},expression:"value.observacao"}})]}}])})],1)],1),_c('v-divider',{staticClass:"my-5"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","lg":"4"}},[_c('validation-provider',{attrs:{"name":"data_inicio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(['##:##']),expression:"['##:##']"}],attrs:{"error-messages":errors,"label":"Hora Inicial","prepend-icon":"mdi-clock-time-four-outline","hint":"Escolha a hora inicial.","persistent-hint":"","disabled":!!_vm.value.hora_fim},model:{value:(_vm.value.hora_inicio),callback:function ($$v) {_vm.$set(_vm.value, "hora_inicio", $$v)},expression:"value.hora_inicio"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"12","lg":"4"}},[_c('validation-provider',{attrs:{"name":"data_fim","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(['##:##']),expression:"['##:##']"}],attrs:{"error-messages":errors,"label":"Hora Final","prepend-icon":"mdi-clock-time-four-outline","hint":"Escolha a hora final.","persistent-hint":"","rules":_vm.rules,"disabled":!_vm.value.hora_inicio},model:{value:(_vm.value.hora_fim),callback:function ($$v) {_vm.$set(_vm.value, "hora_fim", $$v)},expression:"value.hora_fim"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"12","lg":"4"}},[_c('validation-provider',{attrs:{"name":"qt_caminhoes","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"Caminhões","hint":"Indique a quantidade de caminhões permitida.","error-messages":errors,"persistent-hint":"","prepend-icon":"mdi-truck-check-outline"},model:{value:(_vm.value.qt_caminhoes),callback:function ($$v) {_vm.$set(_vm.value, "qt_caminhoes", $$v)},expression:"value.qt_caminhoes"}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }